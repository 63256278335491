import axios from "axios";
import { toast } from 'react-toastify';


const BASE_URL = 'https://cityrails-813c827db6cf.herokuapp.com/';

// const BASE_URL = 'http://localhost:4000/';

const setupAPI = function () {
//   console.log('process.env.REACT_APP_STAGE', process.env.REACT_APP_STAGE);
//   switch (process.env.REACT_APP_STAGE) {
//     case 'production':
//       BASE_URL = 'https://rails-prod.voxprosoftware.com';
//       break;
//     case 'staging':
//       BASE_URL = 'https://uat-backend.voxprosoftware.com/';
//       break;
//     case 'development':
//       BASE_URL = 'http://localhost:3001';
//       break;
//     default:
//       BASE_URL = 'https://uat-backend.voxprosoftware.com/';
//       break;
//   }
};

setupAPI();

const fetchClient = () => {


  const defaultOptions = {
    baseURL: BASE_URL,
    // timeout: 20000,
    headers: {
      'Content-Type': 'application/json',
      
    }
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  instance.interceptors.response.use(
    function  (successRes) {
      toast.dismiss();
      return successRes;
    },
    function (error) {
      toast.dismiss();
      if(error.response.status==401){
        toast.error(error.response.data.message);
        window.location.href = "/login";



      }

      console.log(error)
      console.log("zaheen")

      return Promise.reject(error);
    }
  );

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const isLoggedIn = JSON.parse(localStorage.getItem('user')) ? true : false;
    const token = localStorage.getItem('token');
    if (isLoggedIn && !config.url.includes("s3.amazonaws.com")) {
      config.headers.Authorization = token ? `Bearer ${token}` : `Bearer ''`;
      config.headers.adminId=localStorage.getItem('adminId')
    }

    return config;
  });

  return instance;
};

export default fetchClient();
