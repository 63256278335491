import React, { useEffect, useState, useContext } from 'react'
import { Container, Stack, Typography, Button, TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment';
import api from '../../api';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
export default function AddClientComponent() {

    const users = JSON.parse(localStorage.getItem('user'));
    const { state } = useLocation();
    const navigate = useNavigate();

    const [showEditButton, setShowEditButton] = useState(false)

    const [showClient, setClient] = useState({
        name: '',
        gstNo: '',
        pinCode: '',
        address: '',
        district: '',
        state: '',
        country: '',
        contactNo: '',
        email: '',
        whatsappNo: ''
    })

    const [showRates, setRates] = useState({
        localRate: 0,
        stateRate: 0,
        nationalRate: 0,
        internationalRate: 0
    })

    const [showErrorClient, setErrorClient] = useState({
        name: '',
        gstNo: '',
        pinCode: '',
        address: '',
        district: '',
        state: '',
        country: '',
        contactNo: '',
        email: '',
        whatsappNo: ''
    })

    const [showRatesError, setRatesError] = useState({
        localRate: '',
        stateRate: '',
        nationalRate: '',
        internationalRate: ''
    })
    useEffect(() => {
        console.log(state && state.showEditClient)
        if (state) {
            setClient(state.showEditClient)
            setRates(state.showEditClient)
            setShowEditButton(true)
        }
    }, []);


    async function pincCode(value) {
        const response = await fetch(`https://api.postalpincode.in/pincode/${value}`);
        var data = await response.json();
        if (response) {
            if (data[0].Status == "Error") {
                setErrorClient({
                    ...showErrorClient,
                    pinCode:
                        'Pincode is incorrect!'
                });
                setClient({ ...showClient, state: '', district: '', country: '' })

            }
            setClient({ ...showClient, state: data[0].PostOffice[0].State, district: data[0].PostOffice[0].District, country: data[0].PostOffice[0].Country })
        }
        setErrorClient({
            ...showErrorClient,
            pinCode: '', state: '', district: '', country: ''
        });
    };

    const onChangeClient = (event) => {
        const { name, value } = event.target;
        var regmob = new RegExp('^([0|+[0-9]{1,5})?([7-9][0-9]{9})$');
        let reemail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        switch (name) {
            case 'name':
                setErrorClient({
                    ...showErrorClient,
                    name:
                        value.length > 1 ? '' : 'Minimum two digits is required!'
                });
                break;
            case 'gstNo':
                setErrorClient({
                    ...showErrorClient,
                    gstNo:
                        value.length > 1 ? '' : 'Minimum two digits is required!'
                });
                break;
            case 'pinCode':
                setErrorClient({
                    ...showErrorClient,
                    pinCode:
                        value.length == 6 ? '' : 'Correct pincode is required!'
                });
                value.length == 6 && pincCode(value);
                break;;
            case 'address':
                setErrorClient({
                    ...showErrorClient,
                    address:
                        value.length > 1 ? '' : 'Minimum two digits is required!'
                });
                break;
            case 'district':
                setErrorClient({
                    ...showErrorClient,
                    district:
                        value.length > 1 ? '' : 'Minimum two digits is required!'
                });
                break;
            case 'state':
                setErrorClient({
                    ...showErrorClient,
                    state:
                        value.length > 1 ? '' : 'Minimum two digits is required!'
                });
                break;
            case 'country':
                setErrorClient({
                    ...showErrorClient,
                    country:
                        value.length > 1 ? '' : 'Minimum two digits is required!'
                });
                break;
            case 'contactNo':
                setErrorClient({
                    ...showErrorClient,
                    contactNo:
                        regmob.test(value) ? '' : 'Incorrect mobile number!'
                });
                break;
            case 'email':
                setErrorClient({
                    ...showErrorClient,
                    email: reemail.test(String(value).toLowerCase()) ? '' : 'email is incorrect!'
                });
                break;

            case 'whatsappNo':
                setErrorClient({
                    ...showErrorClient,
                    whatsappNo:
                        regmob.test(value) ? '' : 'Incorrect whatsapp number!'
                });
                break;

            default:
                break;
        }
        showClient[name] = value;
        setClient(showClient);
    }
    function validateForm(callbackFn) {
        setErrorClient({
            ...showErrorClient,
            name: showClient.name.length > 0 ? '' : 'name is required!',
            gstNo: showClient.gstNo.length > 0 ? '' : 'gst no is required!',
            pinCode: showClient.pinCode.length > 0 ? '' : 'correct pincode is required',
            address: showClient.address.length > 0 ? '' : 'address is required',
            district: showClient.district.length > 0 ? '' : 'district is required, auto-filled with pincode',
            state: showClient.state.length > 0 ? '' : 'state is required, auto-filled with pincode',
            country: showClient.country.length > 0 ? '' : 'country is required, auto-filled with pincode',
            contactNo: showClient.contactNo.length > 0 ? '' : 'valid phone number is required',
            email: showClient.email.length > 0 ? '' : 'valid email is required',
            whatsappNo: showClient.whatsappNo.length > 0 ? '' : 'valid 10 digit number is required'

        });

        setRates({
            ...showRates,
            localRate: showRates.localRate.length == 0 && showRatesError.localRate.length == 0 ? 0 : showRates.localRate,
            stateRate: showRates.stateRate.length == 0 && showRatesError.stateRate.length == 0 ? 0 : showRates.stateRate,
            nationalRate: showRates.nationalRate.length == 0 && showRatesError.nationalRate.length == 0 ? 0 : showRates.nationalRate,
            internationalRate: showRates.internationalRate.length == 0 && showRatesError.internationalRate.length == 0 ? 0 : showRates.internationalRate,




        })

        callbackFn()


    }

    const onClickSave = (e) => {
        e.preventDefault();
        validateForm(submitData)

    }


    const onChangeRates = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'localRate':
                setRatesError({
                    ...showRatesError,
                    localRate:
                        value >= 0 ? '' : 'Enter the correct value'
                });
                break;
            case 'stateRate':
                setRatesError({
                    ...showRatesError,
                    stateRate:
                        value >= 0 ? '' : 'Enter the correct value'
                });
                break;
            case 'nationalRate':
                setRatesError({
                    ...showRatesError,
                    nationalRate:
                        value >= 0 ? '' : 'Enter the correct value'
                });

                break;;
            case 'internationalRate':
                setRatesError({
                    ...showRatesError,
                    internationalRate:
                        value >= 0 ? '' : 'Enter the correct value'
                });
                break;


            default:
                break;
        }
        showRates[name] = value;
        setRates(showRates);
    }

    function submitData() {


        let xyz = Object.values(showClient)
        let dataShow = xyz.map(function (val) {
            if (val.length > 0) {

                return false
            }
        })
        let abc = Object.values(showErrorClient)
        let dataShow1 = abc.map(function (val) {
            if (val.length > 0) {

                return false
            }
        })
        let pqrs = Object.values(showRatesError)
        let dataShow2 = pqrs.map(function (val) {
            if (val.length > 0) {

                return false
            }
        })
        if (dataShow.includes(false) && !dataShow1.includes(false) && !dataShow2.includes(false)) {
            // setClient({...showClient, localRate:showRates.localRate, stateRate:showRates.stateRate, nationalRate:showRates.nationalRate, internationalRate:showRates.internationalRate, adminId:user.adminId})

            api
                .post('/api/v1/clients', {
                    showClient,
                    showRates
                })
                .then((response) => {
                    if (response.data.success) {
                        const currentUser = response.data;

                        //   localStorage.setItem('token', response.data.token);
                        //   localStorage.setItem('user', JSON.stringify(response.data.data));

                        //window.location.href = '/dashboard-admin';
                        navigate("/dashboard-admin");
                    }
                    else if (response.data.message === "Your account was de-activated, please contact support!") {


                    }
                    else {
                    }
                })
                .catch(() => {
                });
        }
    }
    function editData() {


        let xyz = Object.values(showClient)
        let dataShow = xyz.map(function (val) {
            if (val.length > 0) {

                return false
            }
        })
        let abc = Object.values(showErrorClient)
        let dataShow1 = abc.map(function (val) {
            if (val.length > 0) {

                return false
            }
        })
        let pqrs = Object.values(showRatesError)
        let dataShow2 = pqrs.map(function (val) {
            if (val.length > 0) {

                return false
            }
        })
        if (dataShow.includes(false) && !dataShow1.includes(false) && !dataShow2.includes(false)) {

            api
                .put('/api/v1/clients', {
                    showClient,
                    showRates
                })
                .then((response) => {
                    if (response.data.success == true) {

                        setTimeout(() => {
                            toast.success(response.data.message);
                        }, 500);
                        navigate("/clients");
                    }
                    else {

                    }
                })
                .catch(() => {

                });
        }
    }


    const onClickCancel = () => {

        setClient({
            name: '',
            gstNo: '',
            pinCode: '',
            address: '',
            district: '',
            state: '',
            country: '',
            contactNo: '',
            email: '',
            whatsappNo: ''
        })


        setRates({
            localRate: 0,
            stateRate: 0,
            nationalRate: 0,
            internationalRate: 0
        })

        setErrorClient({
            name: '',
            gstNo: '',
            pinCode: '',
            address: '',
            district: '',
            state: '',
            country: '',
            contactNo: '',
            email: '',
            whatsappNo: ''
        })
        setRatesError({
            localRate: '',
            stateRate: '',
            nationalRate: '',
            internationalRate: ''
        })

    }


    return (
        <>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {showEditButton == true ? "Edit Client" : "Add Client"}
                    </Typography>
                    <Button variant="contained" onClick={onClickCancel}>
                        New User
                    </Button>
                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField fullWidth
                        id="outlined-basic"
                        label="Name" name='name'
                        onChange={onChangeClient}
                        variant="outlined"
                        style={{ marginRight: "10px" }}
                        error={showErrorClient.name.length > 0 ? true : false}
                        helperText={showErrorClient.name.length > 0 ? showErrorClient.name : ''}
                        value={showClient.name}
                    />

                    <TextField id="outlined-basic" fullWidth label="GST No" variant="outlined" style={{ marginRight: "10px" }}
                        name='gstNo'
                        onChange={onChangeClient}
                        error={showErrorClient.gstNo.length > 0 ? true : false}
                        helperText={showErrorClient.gstNo.length > 0 ? showErrorClient.gstNo : ''}
                        value={showClient.gstNo}
                    />

                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField fullWidth id="outlined-basic" label="Pin Code" variant="outlined" style={{ marginRight: "10px" }}
                        name='pinCode'
                        onChange={onChangeClient}
                        error={showErrorClient.pinCode.length > 0 ? true : false}
                        helperText={showErrorClient.pinCode.length > 0 ? showErrorClient.pinCode : ''}
                        type='number'
                        value={showClient.pinCode}
                    />
                    <TextField id="outlined-basic" fullWidth label="Address" variant="outlined" style={{ marginRight: "10px" }}
                        name='address'
                        onChange={onChangeClient}
                        error={showErrorClient.address.length > 0 ? true : false}
                        helperText={showErrorClient.address.length > 0 ? showErrorClient.address : ''}
                        value={showClient.address}
                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField fullWidth id="outlined-basic" label="District" variant="outlined" style={{ marginRight: "10px" }}
                        name='district'
                        onChange={onChangeClient}
                        error={showErrorClient.district.length > 0 ? true : false}
                        helperText={showErrorClient.district.length > 0 ? showErrorClient.district : ''}
                        value={showClient.district}
                    />
                    <TextField id="outlined-basic" fullWidth label="State" variant="outlined" style={{ marginRight: "10px" }}
                        name='state'
                        onChange={onChangeClient}
                        error={showErrorClient.state.length > 0 ? true : false}
                        helperText={showErrorClient.state.length > 0 ? showErrorClient.state : ''}
                        value={showClient.state}

                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField fullWidth id="outlined-basic" label="Country" variant="outlined" style={{ marginRight: "10px" }}
                        name='country'
                        onChange={onChangeClient}
                        error={showErrorClient.country.length > 0 ? true : false}
                        helperText={showErrorClient.country.length > 0 ? showErrorClient.country : ''}
                        value={showClient.country}

                    />
                    <TextField id="outlined-basic" fullWidth label="Contact No" variant="outlined" style={{ marginRight: "10px" }}
                        name='contactNo'
                        onChange={onChangeClient}
                        error={showErrorClient.contactNo.length > 0 ? true : false}
                        helperText={showErrorClient.contactNo.length > 0 ? showErrorClient.contactNo : ''}
                        value={showClient.contactNo}
                        InputProps={showClient.contactNo && showClient.contactNo.length > 0 && { startAdornment: <InputAdornment position="start">+91 - </InputAdornment> }}
                        type='number'

                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField fullWidth id="outlined-basic" label="Email" variant="outlined" style={{ marginRight: "10px" }}
                        name='email'
                        onChange={onChangeClient}
                        error={showErrorClient.email.length > 0 ? true : false}
                        helperText={showErrorClient.email.length > 0 ? showErrorClient.email : ''}
                        type='email'
                        value={showClient.email}
                    />
                    <TextField id="outlined-basic" fullWidth label="Whatsapp No" variant="outlined" style={{ marginRight: "10px" }}
                        name='whatsappNo'
                        onChange={onChangeClient}
                        error={showErrorClient.whatsappNo.length > 0 ? true : false}
                        helperText={showErrorClient.whatsappNo.length > 0 ? showErrorClient.whatsappNo : ''}
                        InputProps={showClient.whatsappNo && showClient.whatsappNo.length > 0 && { startAdornment: <InputAdornment position="start">+91 - </InputAdornment> }}
                        type='number'
                        value={showClient.whatsappNo}
                    />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={5}>
                    <Typography variant="h7" gutterBottom>
                        Add Rates
                    </Typography>

                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField fullWidth id="outlined-basic" label="Local" variant="outlined" style={{ marginRight: "10px" }}
                        InputProps={{ endAdornment: <InputAdornment position="end">₹/kg </InputAdornment> }}
                        value={showRates.localRate}
                        name='localRate'
                        onChange={onChangeRates}
                        type='number'
                        error={showRatesError.localRate.length > 0 ? true : false}
                        helperText={showRatesError.localRate.length > 0 ? showRatesError.localRate : ''}



                    />
                    <TextField id="outlined-basic" fullWidth label="State" variant="outlined" style={{ marginRight: "10px" }}
                        InputProps={{ endAdornment: <InputAdornment position="end">₹/kg </InputAdornment> }}
                        value={showRates.stateRate}
                        name='stateRate'
                        onChange={onChangeRates}
                        type='number'
                        error={showRatesError.stateRate.length > 0 ? true : false}
                        helperText={showRatesError.stateRate.length > 0 ? showRatesError.stateRate : ''}


                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField fullWidth id="outlined-basic" label="National" variant="outlined" style={{ marginRight: "10px" }}
                        InputProps={{ endAdornment: <InputAdornment position="end">₹/kg </InputAdornment> }}
                        value={showRates.nationalRate}
                        name='nationalRate'
                        onChange={onChangeRates}
                        type='number'
                        error={showRatesError.nationalRate.length > 0 ? true : false}
                        helperText={showRatesError.nationalRate.length > 0 ? showRatesError.nationalRate : ''}


                    />
                    <TextField id="outlined-basic" fullWidth label="International" variant="outlined" style={{ marginRight: "10px" }}
                        InputProps={{ endAdornment: <InputAdornment position="end">₹/kg </InputAdornment> }}
                        value={showRates.internationalRate}
                        name='internationalRate'
                        onChange={onChangeRates}
                        type='number'
                        error={showRatesError.internationalRate.length > 0 ? true : false}
                        helperText={showRatesError.internationalRate.length > 0 ? showRatesError.internationalRate : ''}

                    />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent={'center'} mt={5} mb={5}>
                    {showEditButton == true ? <Button variant="contained" style={{ marginRight: "10px" }} onClick={editData} >
                        Edit
                    </Button> : <Button variant="contained" style={{ marginRight: "10px" }} onClick={(e) => onClickSave(e)} >
                        Add
                    </Button>}
                    <Button variant="contained" color='error' onClick={onClickCancel}>
                        Cancel
                    </Button>
                </Stack>
            </Container>
        </>

    )
}
