import React, { useEffect, useState } from 'react'
import api from '../../api';
import {
    Container,
    Stack,
    Typography,
    Button,
    TextField,
    Card,
    TableRow,
    TableBody,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    IconButton,
    MenuItem,
    Popover,
    TablePagination,
    Pagination,
    Select,
    FormControl,
    InputLabel,
} from '@mui/material'
import Scrollbar from '../scrollbar/Scrollbar';
import Iconify from '../iconify/Iconify';
import { useNavigate } from 'react-router-dom';
import CircularProgressWithLabel from '../../utils/loader'

export default function EmployeesComponent() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(null);
    const [showEmployees, setEmployees] = useState([])
    const [showEditClient, setEditClient] = useState([])
    
    const [showLoader, setShowLoader] = useState(false);

    const [searchPageCount, setSearchPageCount] = useState({
        page: 1,
        total_pages: 0,
        total: 0,
        current_page: 1
    });
    const [searchQuery, setSearchQuery] = useState({
        aadharNo: "",
        firstName: "",
        employeeType: "",
        phoneNumber: ""
    });
    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(null);
    };

    useEffect(() => {
        getClients()
    }, []);

  

    function getClients() {
        setShowLoader(true)
        api.post('/api/v1/employees/allEmployees', { page: searchPageCount.page, query: searchQuery })
            .then((response) => {
                if (response.data.success) {
                    setEmployees([...response.data.employees])
                    setSearchPageCount(response.data.pageInfo)
                   
                    setShowLoader(false)
                }
                else if (response.data.message === "Your account was de-activated, please contact support!") {
                    toast.error(response.data.message);
                    setShowLoader(false)

                }
                else {
                    toast.error(response.data.message);
                    setShowLoader(false)
                }
            })
            .catch(() => {
                toast.error('Something went wrong!');
            });
    }


    const viewMoreResult = (event, newPage) => {
        searchPageCount.page = newPage;
        setSearchPageCount({ ...searchPageCount });
        getClients();
    };


    const onChangeSearch = (event) => {
        const { name, value } = event.target;
        searchQuery[name] = value;
        setSearchQuery(searchQuery);
    }

    const onClickSearch = () => {
        getClients();
    }

    const onClickEditJob = () => {
        navigate('/add-employee', { state: { showEditClient } })
    }
    const onClickAddNew = () => {
        navigate('/add-employee')
    }

    const onClickAddEditData = (data) => {
        setEditClient(data)
    }


    return (
        <>
            <Container maxWidth={false}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Employees
                    </Typography>
                    <Button variant="contained" onClick={onClickAddNew}>
                        Add New Employee
                    </Button>
                </Stack>
                <Card style={{ marginBottom: "30px" }}>
                    <Stack direction="row" sx={{ my: 2 }}>
                        <TextField
                            id="outlined-basic"
                            label="First Name" name='firstName'
                            variant="outlined"
                            style={{ margin: "10px" }}
                            size="small"
                            fullWidth
                            // value={searchQuery.name}
                            onChange={onChangeSearch}
                        />

                        <TextField id="outlined-basic" label="Aadhar No" variant="outlined" style={{ margin: "10px" }}
                            name='aadharNo' size="small"
                            // value={searchQuery.gstNo}
                            onChange={onChangeSearch}

                            fullWidth />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Employee Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="outlined-basic"
                                variant="outlined"
                                // value={showEmployee.employeeType}
                                label="Employee Type"
                                name='employeeType'
                                onChange={onChangeSearch}
                            >
                                <MenuItem value={"Manager"}>Manager</MenuItem>
                                <MenuItem value={"Delivery Person"}>Delivery Person</MenuItem>
                                <MenuItem value={"Store Incharge"}>Store Incharge</MenuItem>
                            </Select></FormControl>
                        <TextField id="outlined-basic" label="Phone No" variant="outlined" style={{ margin: "10px" }}
                            name='phoneNumber' size="small"
                            // value={searchQuery.contactNo}
                            onChange={onChangeSearch}
                            fullWidth />

                        <Button variant="contained" style={{ margin: "10px" }} onClick={onClickSearch} >
                            Search
                        </Button>
                    </Stack>
                </Card>
                <Card>
                    {showLoader ? <CircularProgressWithLabel  /> :
                        <>
                            <TableContainer >
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                Id
                                            </TableCell>
                                            <TableCell align="center">
                                                Name
                                            </TableCell>
                                            <TableCell align="center">
                                                Aadhar Number
                                            </TableCell>
                                            <TableCell align="center">
                                                Phone Number
                                            </TableCell>
                                            <TableCell align="center">
                                                Address
                                            </TableCell>
                                            <TableCell align="center">
                                                Employee Type
                                            </TableCell>
                                            <TableCell align="center">
                                                Email
                                            </TableCell>
                                            <TableCell align="center">
                                                Date Of Joining
                                            </TableCell>
                                            <TableCell align="center">
                                                Date Of Birth
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {showEmployees.length > 0 && showEmployees.map((employee, index) => (
                                            <TableRow hover>
                                                <TableCell align="center">{employee.user.id}</TableCell>

                                                <TableCell align="center">
                                                    <b>
                                                        {employee.user.name}
                                                    </b>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {employee.aadharNo}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {employee.phoneNumber}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {employee.address}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {employee.employeeType}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {employee.user.email}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {(employee.dateOfJoining).split("-").reverse().join("-")}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {(employee.dateOfBirth).split("-").reverse().join("-")}
                                                </TableCell>
                                                <TableCell align="right" onClick={() => onClickAddEditData(employee)}>
                                                    <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                                                        <Iconify icon={'eva:more-vertical-fill'} />
                                                    </IconButton>

                                                </TableCell>
                                            </TableRow>
                                        ))
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack alignItems="center">
                                <Pagination
                                    color='primary'
                                    onChange={viewMoreResult}
                                    page={searchPageCount.current_page}
                                    count={searchPageCount.total_pages}
                                />
                            </Stack>

                            <Popover
                                open={Boolean(open)}
                                anchorEl={open}
                                onClose={handleCloseMenu}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                PaperProps={{
                                    sx: {
                                        p: 1,
                                        width: 140,
                                        '& .MuiMenuItem-root': {
                                            px: 1,
                                            typography: 'body2',
                                            borderRadius: 0.75,
                                        },
                                    },
                                }}
                            >
                                <MenuItem onClick={onClickEditJob}>
                                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                    Edit
                                </MenuItem>

                                <MenuItem sx={{ color: 'error.main' }}>
                                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                                    Delete
                                </MenuItem>
                            </Popover>
                        </>
                    }
                </Card>
            </Container>


        </>
    )
}