// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard-admin',
    icon: icon('ic_analytics'),
  },
  {
    title: 'add package',
    path: '/add-package',
    icon: icon('ic_cart'),
  },
  {
    title: 'packages list',
    path: '/packages',
    icon: icon('ic_package'),
  },
  {
    title: 'clients',
    path: '/clients',
    icon: icon('ic_user'),
  },
  {
    title: 'add clients',
    path: '/add-client',
    icon: icon('ic_cart'),
  },
  {
    title: 'employees',
    path: '/employees',
    icon: icon('ic_user'),
  },
  {
    title: 'add employees',
    path: '/add-employee',
    icon: icon('ic_cart'),
  },
  {
    title: 'user',
    path: '/user',
    icon: icon('ic_user'),
  },
  {
    title: 'product',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
  },
  {
    title: 'login',
    path: '/login',
    icon: icon('ic_lock'),
  },
  {
    title: 'Not found',
    path: '/404',
    icon: icon('ic_disabled'),
  },
];

export default navConfig;
