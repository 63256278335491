import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function CircularProgressWithLabel(props) {
  return (
    <Box sx={{  display: 'flex' , margin:"20%", alignItems: 'center',
    justifyContent: 'center' }}>
      <CircularProgress  size={50} {...props} />
     
    </Box>
  );
}

