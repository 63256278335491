import React, { useEffect, useState, useContext } from 'react'
import {
    Container,
    Stack,
    Typography,
    Button,
    TextField,
    Select,
    InputLabel,
    MenuItem,
    Autocomplete,
    Tabs,
    Tab,
    IconButton,
    FormControl,
    
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment';
import api from '../../api';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from '../iconify/Iconify';
export default function AddEmployeeComponent() {

    const [showEmployee, setEmployee] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        password: generatePassword(),
        confirmPassword: generatePassword(),
        aadharNo: "",
        address: "",
        phoneNumber: "",
        dateOfBirth: "",
        dateOfJoining: "",
        employeeType: "",
        adminId:localStorage.getItem('adminId'),
        role:"employee",
        name:""

    })


    function generatePassword() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setEmployee((prevEmployee) => ({ ...prevEmployee, [name]: value }));
        console.log(value)
    };


    const saveEmployee = ()=>{
        let user= {...showEmployee, name:showEmployee.firstName + " " + showEmployee.lastName}

        if (showEmployee.password !="" && showEmployee.confirmPassword !="" & showEmployee.employeeType != ""
        && showEmployee.email !="" &&showEmployee.password==showEmployee.confirmPassword
        ) {

            api
                .post('/api/v1/employees/', {
                    user
                })
                .then((response) => {
                    if (response.data.message=="Employee created successfully") {
                        toast.success(response.data.message);
                        onClickCancel()
                    }
                    
                    else {
                        toast.error(response.data.message);
                        onClickCancel()
                    }
                })
                .catch(() => {
                });
        }
        else{
            toast.error("Check all the fields");
        }
    }
    const onClickCancel = ()=>{
         setEmployee({
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            password: generatePassword(),
            confirmPassword: generatePassword(),
            aadharNo: "",
            address: "",
            phoneNumber: "",
            dateOfBirth: "",
            dateOfJoining: "",
            employeeType: "",
            adminId:localStorage.getItem('adminId'),
            role:"employee",
            name:""
    
        }) 
    }
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Add Employee
                    </Typography>

                </Stack>

                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField id="outlined-basic" fullWidth label="First Name" variant="outlined" style={{ marginRight: "10px" }}
                        name='firstName'
                        value={showEmployee.firstName}
                        onChange={onChangeValue}


                    />
                    <TextField id="outlined-basic" fullWidth label="Middle Name" variant="outlined" style={{ marginRight: "10px" }}
                        name='middleName'
                        value={showEmployee.middleName}

                        onChange={onChangeValue}

                    />
                    <TextField id="outlined-basic" fullWidth label="Last Name" variant="outlined" style={{ marginRight: "10px" }}
                        name='lastName'
                        value={showEmployee.lastName}

                        onChange={onChangeValue}

                    />

                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField id="outlined-basic" fullWidth label="Email" variant="outlined" style={{ marginRight: "10px" }}
                        type='email'
                        name='email'
                        value={showEmployee.email}
                        onChange={onChangeValue}

                    />

                    <TextField id="outlined-basic" fullWidth label="Password" variant="outlined" style={{ marginRight: "10px" }}
                        name='password'
                        value={showEmployee.password}
                        onChange={onChangeValue}
                    />
                    <TextField id="outlined-basic" fullWidth label="Confirm Password" variant="outlined" style={{ marginRight: "10px" }}
                        name='confirmPassword'
                        value={showEmployee.confirmPassword}
                        onChange={onChangeValue}
                    />

                </Stack>

                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField id="outlined-basic" fullWidth label="Aadhar No" variant="outlined" style={{ marginRight: "10px" }}
                        name='aadharNo'
                        type='number'
                        value={showEmployee.aadharNo}
                        onChange={onChangeValue}


                    />
                    <TextField id="outlined-basic" fullWidth label="Address" variant="outlined" style={{ marginRight: "10px" }}
                        name='address'
                        value={showEmployee.address}
                        onChange={onChangeValue}
                    />
                    <TextField id="outlined-basic" fullWidth label="Phone Number" variant="outlined" style={{ marginRight: "10px" }}
                        name='phoneNumber'
                        type='number'
                        value={showEmployee.phoneNumber}
                        onChange={onChangeValue}
                    />

                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>

                    <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Date Of Birth"
                        variant="outlined"
                        style={{ marginRight: '10px' }}
                        name="dateOfBirth"
                        type="date"
                        value={showEmployee.dateOfBirth}
                        onChange={onChangeValue}
                    />

                    <TextField id="outlined-basic" fullWidth label="Date Of Joining" variant="outlined" style={{ marginRight: "10px" }}
                        name='dateOfJoining'
                        type='date'
                        value={showEmployee.dateOfJoining}
                        onChange={onChangeValue}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Employee Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={showEmployee.employeeType}
                            label="Employee Type"
                            name='employeeType'
                            onChange={onChangeValue}
                        >
                            <MenuItem value={"Manager"}>Manager</MenuItem>
                            <MenuItem value={"Delivery Person"}>Delivery Person</MenuItem>
                            <MenuItem value={"Store Incharge"}>Store Incharge</MenuItem>
                        </Select></FormControl>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent={'center'} mt={5} mb={5}>
                    <Button variant="contained" style={{ marginRight: "10px" }} onClick={saveEmployee} >
                        Add
                    </Button>
                    <Button variant="contained" color='error' onClick={onClickCancel} >
                        Cancel
                    </Button>
                </Stack>
            </Container>
        </>
    )
}