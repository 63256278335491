import React, { useEffect, useState } from 'react'
import api from '../../api';
import {
    Container,
    Stack,
    Typography,
    Button,
    TextField,
    Card,
    TableRow,
    TableBody,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    IconButton,
    MenuItem,
    Popover,
    TablePagination,
    Pagination
} from '@mui/material'
import Scrollbar from '../scrollbar/Scrollbar';
import Iconify from '../iconify/Iconify';
import { useNavigate } from 'react-router-dom';
import CircularProgressWithLabel from '../../utils/loader'


export default function ClientsComponent() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(null);
    const [showClients, setClients] = useState([])
    const [showEditClient, setEditClient] = useState([])
    const [showLoader, setShowLoader] = useState(false);

    const [searchPageCount, setSearchPageCount] = useState({
        page: 1,
        total_pages: 0,
        total: 0,
        current_page: 1
    });
    const [searchQuery, setSearchQuery] = useState({
        pinCode: "",
        name: "",
        gstNo: "",
        contactNo: ""
    });
    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(null);
    };

    useEffect(() => {
        getClients()
    }, []);

    function getClients() {
    setShowLoader(true)
        api.post('/api/v1/clients/allClients', { page: searchPageCount.page, query: searchQuery })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.clients)
                    setClients([...response.data.clients])
                    setSearchPageCount(response.data.pageInfo)
                    setShowLoader(false)
                }
                else if (response.data.message === "Your account was de-activated, please contact support!") {
                    toast.error(response.data.message);
                    setShowLoader(false)

                }
                else {
                    toast.error(response.data.message);
                    setShowLoader(false)
                }
            })
            .catch(() => {
                toast.error('Something went wrong!');
            });
    }


    const viewMoreResult = (event, newPage) => {
        searchPageCount.page = newPage;
        setSearchPageCount({ ...searchPageCount });
        getClients();
    };


    const onChangeSearch = (event) => {
        const { name, value } = event.target;
        searchQuery[name] = value;
        setSearchQuery(searchQuery);
    }

    const onClickSearch = () => {
        getClients();
    }

    const onClickEditJob = () => {
        navigate('/add-client', { state: { showEditClient } })
    }
    const onClickAddNew = () => {
        navigate('/add-client')
    }

    const onClickAddEditData = (data) => {
        setEditClient(data)
    }
    return (
        <>
            <Container maxWidth={false}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Clients
                    </Typography>
                    <Button variant="contained" onClick={onClickAddNew}>
                        Add New Client
                    </Button>
                </Stack>
                <Card style={{ marginBottom: "30px" }}>
                    <Stack direction="row" sx={{ my: 2 }}>
                        <TextField
                            id="outlined-basic"
                            label="Name" name='name'
                            variant="outlined"
                            style={{ margin: "10px" }}
                            size="small"
                            fullWidth
                            // value={searchQuery.name}
                            onChange={onChangeSearch}
                        />

                        <TextField id="outlined-basic" label="GST No" variant="outlined" style={{ margin: "10px" }}
                            name='gstNo' size="small"
                            // value={searchQuery.gstNo}
                            onChange={onChangeSearch}

                            fullWidth />
                        <TextField id="outlined-basic" label="Pincode No" variant="outlined" style={{ margin: "10px" }}
                            name='pinCode' size="small"
                            // value={searchQuery.pinCode}
                            onChange={onChangeSearch}

                            fullWidth />
                        <TextField id="outlined-basic" label="Contact No" variant="outlined" style={{ margin: "10px" }}
                            name='contactNo' size="small"
                            // value={searchQuery.contactNo}
                            onChange={onChangeSearch}
                            fullWidth />

                        <Button variant="contained" style={{ margin: "10px" }} onClick={onClickSearch} >
                            Search
                        </Button>
                    </Stack>
                </Card>
                <Card>
                {showLoader ? <CircularProgressWithLabel  /> :
                        <>
                    <TableContainer >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        Id
                                    </TableCell>
                                    <TableCell align="center">
                                        Name
                                    </TableCell>
                                    <TableCell align="center">
                                        Address
                                    </TableCell>
                                    <TableCell align="center">
                                        GstNo
                                    </TableCell>
                                    <TableCell align="center">
                                        Pincode
                                    </TableCell>
                                    <TableCell align="center">
                                        Contact
                                    </TableCell>
                                    <TableCell align="center">
                                        Email
                                    </TableCell>
                                    <TableCell align="center">
                                        Whatsapp
                                    </TableCell>
                                    <TableCell align="center">
                                        Local
                                    </TableCell>
                                    <TableCell align="center">
                                        State
                                    </TableCell>
                                    <TableCell align="center">
                                        National
                                    </TableCell>
                                    <TableCell align="center">
                                        International
                                    </TableCell>
                                    <TableCell>

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {showClients.length > 0 && showClients.map((client, index) => (
                                    <TableRow hover>
                                        <TableCell align="center">{client.id}</TableCell>

                                        <TableCell align="center">
                                            <b>
                                                {client.name}
                                            </b>
                                        </TableCell>
                                        <TableCell align="center">{client.address}</TableCell>
                                        <TableCell align="center">{client.gstNo}</TableCell>
                                        <TableCell align="center">{client.pinCode}</TableCell>
                                        <TableCell align="center">{client.contactNo}</TableCell>
                                        <TableCell align="center">{client.email}</TableCell>
                                        <TableCell align="center">{client.whatsappNo}</TableCell>
                                        <TableCell align="center">{client.localRate}</TableCell>
                                        <TableCell align="center">{client.stateRate}</TableCell>
                                        <TableCell align="center">{client.nationalRate}</TableCell>
                                        <TableCell align="center">{client.internationalRate}</TableCell>
                                        <TableCell align="right" onClick={() => onClickAddEditData(client)}>
                                            <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                                                <Iconify icon={'eva:more-vertical-fill'} />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ))
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Stack alignItems="center">
                        <Pagination
                            color='primary'
                            onChange={viewMoreResult}
                            page={searchPageCount.current_page}
                            count={searchPageCount.total_pages}
                        />
                    </Stack>

                    <Popover
                        open={Boolean(open)}
                        anchorEl={open}
                        onClose={handleCloseMenu}
                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        PaperProps={{
                            sx: {
                                p: 1,
                                width: 140,
                                '& .MuiMenuItem-root': {
                                    px: 1,
                                    typography: 'body2',
                                    borderRadius: 0.75,
                                },
                            },
                        }}
                    >
                        <MenuItem onClick={onClickEditJob}>
                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                            Edit
                        </MenuItem>

                        <MenuItem sx={{ color: 'error.main' }}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                            Delete
                        </MenuItem>
                    </Popover>
                    </>
                    }
                </Card>
            </Container>


        </>
    )
}