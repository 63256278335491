import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography,Box, Divider, Stack, Button, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
// hooks

import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../logo/Logo';
import Iconify from '../iconify/Iconify';

// sections
import { LoginForm } from '../../sections/auth/login';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import { LoadingButton } from '@mui/lab';
// components
// ----------------------------------------------------------------------
import api from '../../api';
import { toast } from 'react-toastify';
// ----------------------------------------------------------------------
import CircularProgress from '@mui/material/CircularProgress';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginComponent() {
  const mdUp = useResponsive('up', 'md');

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const handleClick = () => {
    // handleLogin()
    login();
    // navigate('/dashboard', { replace: false });

  };

  // const [account, setAccount] = useState({
  //   email: 't4snietzaheen@gmail.com',
  //   password: 'zaheen123'
  // });

  const [account, setAccount] = useState({
    email: 'zaheenkasim@hotmail.com',
    password: 'password'
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "email") {
      localStorage.setItem('eamilVal', e.target.value);
    }
    else if (name === "password") {
      localStorage.setItem('passwordVal', e.target.value);
    }
    account[name] = value;
    setAccount(account);
  };




  const login = () => {
  setShowLoader(true)

    api
      .post('/sessions',{
        email: account.email,
        password: account.password
      })
      .then((response) => {
        if (response.data) {
         
          
         
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('role', response.data.role);
          localStorage.setItem('adminId', response.data.user.adminId==null?response.data.user.id:response.data.user.adminId);
          localStorage.setItem('user', JSON.stringify(response.data.user));
        
          //window.location.href = '/dashboard-admin';                                                                                                                
          navigate("/dashboard-admin");
          toast.success("Logged in successfull");
          setShowLoader(false)
        }
        else if (response.data.message === "Your account was de-activated, please contact support!") {
          toast.error(response.data.message);

        }
        else {
          toast.error(response.error);  
        }
      })
      .catch(() => {
        toast.error('Something went wrong!');
      });
      setShowLoader(false)  
  };


  return (
    <>
      <Helmet>
        <title> City Courier </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome to <br />City Courier Services
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2">Contact Admin</Link>
            </Typography>

            <Stack spacing={3}>
              <TextField name="email" label="Email address" />

              <TextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              {/* <Checkbox name="remember" label="Remember me" /> */}
              <Link variant="subtitle2" underline="hover">
                Forgot password?
              </Link>
            </Stack>

          {showLoader ?  
       <LoadingButton fullWidth disabled size="large" type="submit" variant="contained" onClick={handleClick}>
    <CircularProgress  size={40}  />
            </LoadingButton>:  <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
              Login
            </LoadingButton>}

            {/* <LoginForm /> */}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
